import "bootstrap";

import { callBellIntegration } from "../components/callBell";
import { confettiDisplay } from "../components/confetti";
import { displayModalOverlay, resourceModal } from "../components/modalDisplay";
// import { hotjarInit } from '../components/hotjar';
import {
  faqDropdown,
  commentsDropdown,
  leerMasDropdown,
  progressDropDown,
  dropDownOptionsToText,
  sideNavbarDropDown,
} from "../components/dropDown";
import {
  redirectedSituationsDisplay,
  missingReportsNotification,
} from "../components/multiForm";
import {
  completedSelfEvaluation,
  disableCourseIntroBtn,
  videoPlayerEvents,
} from "../components/videoPlayer";
import { browserAlert } from "../components/browserAlert";
import {
  navItemSelector,
  legalNoticeSelector,
  pricacyPolicySelector,
} from "../components/navItems";
import { videoPopup } from "../components/videoPopup";
import { setHasWatchedTutorial } from "../components/setHasWatchedTutorial";
import { situationNextBtn } from "../components/situationNextBtn";
import "../src/add-eu-banner";
import { acceptTerms } from "../components/acceptTerm";
import { resourcesCarousel } from "../components/feedbackCarousel";
import { hoverTooltip, tooltip } from "../components/toolTip";
import { videoPlayerButton } from "../components/myProgressVideo";
import { tags } from "../components/tags";
import { faceChart } from "../components/faceChart";
import {
  enterAndExitEvents,
  mixpanelTrackClickEvent,
  backFromRecordMixpanelEvent,
  backFromMotivationMixpanelEvent,
  backFromKeyIdeasMixpanelEvent,
} from "../components/mixpanelCreateEvent";
import { progressIndexSkillCarousel, feedbackSlideActions } from "../components/feedbackCarousel";
import "chartkick/chart.js";
import { displaySideNav } from "../components/navbar";
//import { avatarsLoad } from "../components/avatars";

// /////////////////
const laiaWidget = document.querySelector(".laia-widget");
const laiaExitBtn = document.querySelector(".close-laia-widget");
if (laiaExitBtn) {
  laiaExitBtn.addEventListener("click", () => {
    laiaWidget.classList.add("inactive");
    fetch("/update_laia_notice", {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  });
}
// /////////////////

acceptTerms();
browserAlert();
confettiDisplay();
displayModalOverlay();
dropDownOptionsToText();
faqDropdown();
faceChart();
//hotjarInit();
leerMasDropdown();
missingReportsNotification();
navItemSelector();
legalNoticeSelector();
pricacyPolicySelector();
progressDropDown();
resourceModal();
situationNextBtn();
tags();
hoverTooltip();
resourcesCarousel();
videoPopup();
setHasWatchedTutorial();
videoPlayerButton();
videoPlayerEvents();
enterAndExitEvents();
mixpanelTrackClickEvent();
backFromRecordMixpanelEvent();
backFromMotivationMixpanelEvent();
backFromKeyIdeasMixpanelEvent();
progressIndexSkillCarousel();
sideNavbarDropDown();
displaySideNav();
feedbackSlideActions();
//avatarsLoad();
